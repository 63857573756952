@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Size__extra-small {
  width: $extra-small-logo-size;
}

.Size__small {
  width: $small-logo-size;
}

.Size__medium {
  width: $medium-logo-size;
}

.Size__large {
  width: $large-logo-size;
}

.Size__extra-large {
  width: $extra-large-logo-size;
}

.Responsive {
  width: $small-logo-size;

  @media screen and (min-width: $medium-screen) {
    width: $medium-logo-size;
  }
}
